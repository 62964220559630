import { React, useEffect, useState } from "react";
import LEFTMENU from "./leftMenu";
import Topmenu from "./topmenu";
import '../pages/userdetails.css'
import SubmitBTNIcon from '../assets/sub-btn-icon.png';
import CanCelBtn from '../assets/cancel-claim-btn.png';
import pass_icon from "../assets/pass_lock.png";
import { get_user_details_api, user_remove_api, edit_user_api } from "../api/user_approval_api";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import GIFLOADER from '../assets/loader.gif';
import { useNavigate } from 'react-router-dom';
import view_png from "../assets/view.png"
import { user_password_update_api } from "../api/admin_user_password_update_api";

function UserDetails() {
    const {
        register,
        handleSubmit, setValue, reset,
        formState: { errors },
    } = useForm();
    var { id } = useParams();
    const navigate = useNavigate();
    var [enableloader, SetBtnDisable] = useState(false);
    const [popshow, setPopShow] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    var [suchidden, ShowSuc] = useState(true);
    const [changepass, setChangepass] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    var [validatePassword, SetValidatePassword] = useState(true)
    var [userstatus, setUserstatus] = useState();

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    function backtouser() {
        localStorage.setItem('fromdetails', 'pagecount')
        return navigate('/userlist');
    }
    function deleteUser(event) {
        event.preventDefault();
        setPopShow(!popshow)
    }
    function passchange(event) {
        event.preventDefault();
        setChangepass(!changepass)
    }
    function passcancel() {
        SetValidatePassword(false)
        setChangepass(!changepass)
    }
    function staysamepage() {
        setPopShow(!popshow)
    }
    function deleteClaim() {
        setPopShow(!popshow)
        SetBtnDisable(false);
        var response_data = user_remove_api(id).then(res => {
            SetSuMsg(res.msg)
            ShowSuc(false)
            setTimeout(() => {
                ShowSuc(true)
                SetSuMsg('')
                SetBtnDisable(true);
                return navigate('/userlist')
            }, 3000);

        })
    }

    const onPassSubmit = async (req_data, event) => {
        console.log('dfsdf', req_data.first_password)
        event.preventDefault();
        SetValidatePassword(true);
        SetBtnDisable(false);
        if (req_data.first_password == '') {
            console.log('if')
            SetBtnDisable(true);

            validatePassword && errors.first_password?.type === 'required' && (
                <small className='errMsg'>Password is required</small>
            )

        } else {
            var response = await user_password_update_api(id, req_data.first_password).then(res => {
                SetSuMsg(res.msg)
                ShowSuc(false)
                setChangepass(!changepass)
                setTimeout(() => {
                    ShowSuc(true)
                    SetSuMsg('')
                    SetBtnDisable(true);
                    return navigate('/userlist')
                }, 3000);
            })
        }

    }
    useEffect(() => {
        var response_data = get_user_details_api(id).then(res => {
            console.log(res.data)
            SetBtnDisable(true)
            setUserstatus(res.data.status)
            console.log(res.data.status)
            setValue("account_no", res.data.account_no);
            setValue("first_name", res.data.first_name);
            setValue("last_name", res.data.last_name);
            setValue("email", res.data.email_address);
            setValue("role", res.data.role);
            setValue("company_name", res.data.company_name);
        })
    }, [])
    const onUserSubmit = async (req_data, event) => {
        console.log(req_data)
        event.preventDefault();
        SetBtnDisable(false);
        req_data.user_id = id;
        var response_data = await edit_user_api(req_data)
        console.log(response_data)
        if (response_data.success) {
            SetSuMsg(response_data.msg)
            ShowSuc(false)
            setTimeout(() => {
                ShowSuc(true)
                SetSuMsg('')
                SetBtnDisable(true);
                return navigate('/userlist')
            }, 3000);
        }
    }
    const handleKeyDown = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    };
    const handleKeyDownName = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
        if (!/[a-zA-Z]/.test(event.key)) {
            event.preventDefault();
        }
    };
    function spaceBlock() {
        var space = document.getElementById('company_name');
        if (space.selectionStart === 0 && window.event.code === "Space") {
            window.event.preventDefault();
        }
    }
    return (
        <div className=" sideMenu">
            <LEFTMENU />

            {/* <div className="menuall">
                <div className="lmenu">
                    <LEFTMENU />
                </div>
                <div className="tmenu">
                    <Topmenu />
                </div>
            </div> */}
            {
                popshow && <div className="CanPop">
                    <div className="can_popTotal">
                        <div className='can_title'>
                            Confirmation
                        </div>
                        <hr />
                        <div className='cancel_content'>
                            <p>
                                Are you sure you want to remove the user?
                            </p>
                        </div>
                        <hr />
                        <div className='cancel_btns'>
                            <button className='can_no' onClick={staysamepage}>No</button>
                            <button className='can_yes' onClick={deleteClaim}>Yes</button>
                        </div>
                    </div>
                </div>
            }
            {
                changepass && <div className="CanPop">
                    <div className="can_popTotal">
                        <div className='can_title adminpasstitle'>
                            Update Password
                        </div>
                        <hr />
                        <div>
                            <form className='reset form-area cngpass p-5 adminpass' onSubmit={handleSubmit(onPassSubmit)}>
                                <div className="create_passsword">
                                    <img src={pass_icon} alt="password_icon" />
                                    <input type={passwordShown ? "text" : "password"} placeholder="Password" id="first_password" onKeyDown={handleKeyDown}
                                        {...register("first_password", {
                                            required: validatePassword,
                                            minLength: 8
                                        })} />
                                    <img className="eeyeee" src={view_png} alt="password_icon" onClick={togglePasswordVisiblity} />
                                    <br />
                                    {validatePassword && errors.first_password?.type === 'required' && (
                                        <small className='errMsg'>Password is required</small>
                                    )}
                                    {validatePassword && errors?.first_password?.type === "minLength" && (
                                        <small className='errMsg'>Password cannot less than 8 characters</small>
                                    )}
                                </div>

                                <div className="craft_serch btn-area changePass_btns adminpassbtns">
                                    <button className="changeCan" onClick={passcancel}>Cancel</button>
                                    <button className='change_sub'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            <div className='toaster suc user_suc_pop' hidden={suchidden}>
                <h2>{suMSG}</h2>
            </div>
            <div hidden={enableloader} className='loader'>
                <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
            </div>
            <div className="container details_All">
                <div className="claim_top_bar">
                    <div className="row user_topbar">
                        <div className="col-md-9">
                            <h3> User Details</h3>
                        </div>
                        <div className="col-md-3 userSearch"></div>
                    </div>
                </div>
                <div className="details_card">
                    <div className="card">
                        <div className="card-body">
                            <div className="user_heading">
                                <span>User Information</span>
                                <hr />
                            </div>
                            <form className='' >
                                <div className="row section_gap">
                                    <div className="col-md-6">
                                        <label>Maxon Account Number</label> <br />
                                        <input type="text" className="form-control" placeholder="Enter the Maxon Account Number" onKeyDown={handleKeyDown} maxLength={20} {...register("account_no", {
                                            required: true,
                                        })} />
                                        {errors.account_no?.type === "required" && (
                                            <small className='errMsg'>Please enter the Maxon Account Number</small>
                                        )}
                                    </div>
                                    <div className="col-md-6 SelectDrp">
                                        <label>User Access</label> <br />
                                        <select {...register(`role`)}>
                                            <option value="0">Full Access</option>
                                            <option value="2">View Only</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row section_gap">
                                    <div className="col-md-6">
                                        <label>First Name</label> <br />
                                        <input placeholder='First Name' className='form-control' onKeyDown={handleKeyDownName} maxLength={20}
                                            id="first_name" {...register("first_name", {
                                                required: true,
                                            })} />
                                        {errors.first_name?.type === "required" && (
                                            <small className='errMsg'>Please provide your First Name</small>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label>Last Name</label> <br />
                                        <input placeholder='Last Name' className='form-control' onKeyDown={handleKeyDownName} maxLength={20}
                                            id="last_name"   {...register("last_name", {
                                                required: true,
                                            })} />
                                        {errors.last_name?.type === "required" && (
                                            <small className='errMsg'>Please enter your Last Name</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row section_gap">
                                    <div className="col-md-6">
                                        <label>Email</label> <br />
                                        <input placeholder='Email' className='form-control'
                                            id="email" readOnly {...register("email", {
                                                required: true,
                                            })} />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Company Name</label> <br />
                                        <input placeholder='Company Name' maxLength={50} onKeyDown={spaceBlock} className='form-control'
                                            id="company_name" {...register("company_name", {
                                                required: true,
                                            })} />
                                        {errors.company_name?.type === "required" && (
                                            <small className='errMsg'>Please  provide the Company Name</small>
                                        )}
                                    </div>
                                </div>
                                <div className="all_btns_user">
                                    <div className="userbtns rmvupt">
                                        <button className="delbtnuser" type="button" onClick={(event) => { deleteUser(event) }}>
                                            Remove User</button>
                                        {(() => {
                                            if (userstatus === 1) {
                                                return <div>
                                                    <button className="rtn_can uppass" type="button" onClick={(event) => { passchange(event) }} >Update Password</button>
                                                </div>
                                            }
                                        })()}
                                    </div>
                                    <div className="userbtns">
                                        <button className="rtn_can" type="button" onClick={backtouser}><img src={CanCelBtn} alt='cancel-icon' /> Cancel</button>
                                        <button className="rtn_submit" type="button" onClick={handleSubmit(onUserSubmit)}>
                                            <img src={SubmitBTNIcon} alt='submit-icon' /> Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


export default UserDetails;
