import React from "react";
import ReturnMenu from "./returnsmenu";
import GIFLOADER from '../assets/loader.gif';
import { useState, useEffect } from "react";
import back_icon from "../assets/back_icon.png"
import { get_rma_details_api } from '../api/rma_details_api.js';
import { useForm, useFieldArray } from 'react-hook-form';
import SubmitBTNIcon from '../assets/sub-btn-icon.png';
import CanCelBtn from '../assets/cancel-claim-btn.png';
import DraftBTNIcon from '../assets/save-draft-icon.png';
import { edit_return_claim_api } from '../api/rma_edit_return_api.js';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import { profile_api } from "../api/profile_api.js";
import newlogout from '../assets/logoutblack.svg';
import { Link } from "react-router-dom";
import supabase from "../api/supabaseconnect.js";

function Editreturns() {
    const { register, handleSubmit, control, unregister, getValues, setError, clearErrors, reset, watch, setValue, resetField, setFocus, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const { fields: repair_list, append: appendRepair, remove: removeRepair, insert: insertRepair } = useFieldArray({
        control,
        name: 'parts',
    });

    let [parts, setRepairs] = useState([{
        invoice_no: '', invoice_no_line: '', part_no: '', part_description: '', return_qty: '', reason_code: '', replace_required: '', pickup_arrangements: '', comments: '', invoice_part_list: []
    }]);
    var [enableloader, SetBtnDisable] = useState(false);
    const [activeRepairIndex, setActiveRepairIndex] = useState(0);
    var [IsAllFieldValid, SetIsAllFieldValid] = useState(true);
    var [showERRfields, SetshowERRfields] = useState(false);
    var [showpartvalid, setshowpartvalid] = useState(false);
    const [partquantity, setQuantity] = useState([]);
    const [part_description, setPart_description] = useState([]);
    var [suMSG, SetSuMsg] = useState('');
    var [suchidden, ShowSuc] = useState(false);
    const [part_list, setPartList] = useState([]);
    const [show, setShow] = useState(false);
    var [erMSG, SetErMsg] = useState('');
    var [errhidden, ShowErr] = useState(false);
    var [isSeValidMsg, setisSeiralValidMsg] = useState(false);
    const navigate = useNavigate();
    const [accno, setAccNO] = useState();
    const [selectpart, setselectpart] = useState();
    var [partTabChange, SetpartTabChange] = useState(false);
    const [editapi, setEditapi] = useState(true);
    var [qtyerror, setQtyerror] = useState(false);
    var [reason, setReason] = useState(false);
    var [partinput, setPartInput] = useState(true);
    var [partdrop, setpartdrop] = useState(false);
    const [partValid, setPartvalid] = useState(false);
    var [showpartvalid, setshowpartInvalid] = useState(false);
    const [btnerr, setbtnerr] = useState(false);
    var [invoicedate, setInvoicedate] = useState(false);
    const [partnumber, setpartnumber] = useState()
    const [activeyear, setActiveYear] = useState();
    var [redirectpop, setredirectpop] = useState(false);
    var [ISfile_removed, SetISfile_removed] = useState(false);
    const [userRole, setUserRole] = useState(0);




    var parts_invoice_num = "parts." + activeRepairIndex + ".invoice_no";
    const current_parts_invoice = watch(parts_invoice_num);

    var parts_num_list = "parts." + activeRepairIndex + ".part_no";
    const current_parts_num = watch(parts_num_list);

    var parts_qty_list = "parts." + activeRepairIndex + ".return_qty";
    const current_parts_qty = watch(parts_qty_list);

    var parts_descr = "parts." + activeRepairIndex + ".part_description";
    const current_parts_descr = watch(parts_descr);

    var parts_invo_line = "parts." + activeRepairIndex + ".invoice_no_line";
    const current_parts_involine = watch(parts_invo_line);

    var parts_reason_code = "parts." + activeRepairIndex + ".reason_code";
    const current_parts_reason_code = watch(parts_reason_code);

    var invoice_part_list = "parts." + activeRepairIndex + ".invoice_part_list";
    const current_invoice_part_list = watch(invoice_part_list);
    const invofile_upload = watch('rma_file');

    var return_Req_dataDraft
    var { id } = useParams();

    var [file_invoice, SetFileInvoice] = useState(null);
    var [file_invoiceremove, SetFileInvoiceRemove] = useState(true);
    var [ISfile_removed, SetISfile_removed] = useState(false);
    var [invoice_file_edit, Setinvoice_file_edit] = useState(null);
    var [fileerr, setFileErr] = useState(false);
    var [fileerrsize, setFileErrsize] = useState(false);

    const InvoiceFileRemove = () => {
        SetFileInvoice(null);
        SetFileInvoiceRemove(false);
        Setinvoice_file_edit(null);
        SetISfile_removed(true);
    }
    function cancelpop(event) {
        event.preventDefault();
        localStorage.setItem('fromdetails', 'pagecount')
        setShow(!show)
    }
    function backtoclaims() {
        return navigate('/returns');
    }
    function staysamepage() {
        setShow(!show)
    }
    function cancelqty() {
        setQtyerror(false)
    }
    function cancelreson() {
        setReason(false)
    }
    function cancelinvodate() {
        setInvoicedate(false)
    }

    function gotoview() {
        return navigate(`/returndetails/${id}`);
    }
    function goToList() {
        return navigate('/returns');
    }
    useEffect(() => {
        // console.log(current_parts_reason_code)
        if (current_parts_reason_code === '1A' && !partTabChange && !editapi) {
            setReason(true)
        }
    }, [current_parts_reason_code])
    useEffect(() => {
        SetpartTabChange(true)
        var response_data = get_rma_details_api(id).then(res => {
            SetBtnDisable(enableloader = false);
            // console.log(res)
            if (res.data == undefined || res.data.length == 0) {
                res.data = [{
                    invoice_no: '', invoice_no_line: '', part_no: '', part_description: '', return_qty: '', reason_code: '', replace_required: '', pickup_arrangements: '', comments: '', invoice_part_list: []
                }];
            }
            setRepairs(res.data);
            console.log(res)
            if (res.data[0].status == 1) {
                setredirectpop(true)
            }
            res.data.forEach((e, Rindex) => {
                var Repair_Cate = "parts." + Rindex + ".invoice_no"
                setValue(Repair_Cate, e.invoice_no);
                var Repair_ivnoline = "parts." + Rindex + ".invoice_no_line"
                setValue(Repair_ivnoline, e.invoice_line);
                var Repair_Gate = "parts." + Rindex + ".part_no"
                setValue(Repair_Gate, e.part_no);
                var Repair_Comp = "parts." + Rindex + ".part_description"
                setValue(Repair_Comp, e.part_description);
                var Repair_laourhr = "parts." + Rindex + ".return_qty"
                setValue(Repair_laourhr, e.return_qty);
                var Repair_Ratee = "parts." + Rindex + ".reason_code"
                setValue(Repair_Ratee, e.reason_code);
                var Repair_LabTotal = "parts." + Rindex + ".replace_required"
                setValue(Repair_LabTotal, e.replace_required);
                var Repair_Misc = "parts." + Rindex + ".pickup_arrangements"
                setValue(Repair_Misc, e.pickup_arrangements);
                var Repair_Mdesc = "parts." + Rindex + ".comments"
                setValue(Repair_Mdesc, e.comments);
                var Repair_invo_part_list = "parts." + Rindex + ".invoice_part_list"
                setValue(Repair_invo_part_list, e.invoice_part_list);
            })
            // setValue("invoice_no", res.data[0].invoice_no);
            // setValue("invoice_no_line", res.data[0].invoice_line);
            // setValue("part_no", res.data[0].part_no);
            // setValue("part_description", res.data[0].part_description);
            // setValue("return_qty", res.data[0].return_qty);
            // setValue("reason_code", res.data[0].reason_code);
            // setValue("replace_required", res.data[0].replace_required);
            // setValue("pickup_arrangements", res.data[0].pickup_arrangements);
            // setValue("comments", res.data[0].comments);

            setPartList(res.data[0].invoice_part_list)
            SetBtnDisable(enableloader = true);
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            setActiveYear(year)
            if (res.rma_file) {
                Setinvoice_file_edit(res.rma_file.signedUrl);
                setValue("rma_file", res.rma_file.signedUrl)
            }
        })

    }, [])
    useEffect(() => {
        console.log(invofile_upload)
        // return
        if (invofile_upload != undefined && invofile_upload != '' && invofile_upload != null && invofile_upload.length == 1) {
            console.log(invofile_upload[0].name)
            var fileExt = invofile_upload[0].name.toLowerCase().split('.').pop();
            console.log(fileExt)
            if (fileExt == 'pdf' || fileExt == 'png' || fileExt == 'jpg' || fileExt == 'jpeg') {
                const maxFileSize = 15 * 1024 * 1024; // 15MB limit
                if (invofile_upload[0].size > maxFileSize) {
                    setFileErrsize(true)
                    setFileErr(false)
                    setValue('rma_file', '')
                } else {
                    console.log(invofile_upload[0]);
                    SetFileInvoice(invofile_upload[0]);
                    setFileErr(false)
                    setFileErrsize(false)
                }
            } else {
                setFileErr(true)
                setValue('rma_file', '')
            }
        }
        else {
            SetFileInvoice(null)
        }
    }, [invofile_upload])
    useEffect(() => {

        console.log("current_parts_invoice")
        var response_data = profile_api().then(acc_no => {
            // console.log(acc_no)
            setAccNO(acc_no.data[0].account_no)
        })
        // return
        const request_data = {
            "invoice_no": current_parts_invoice,
            "user_id": localStorage.getItem('user_id'),
        }
        // console.log(request_data)

        const headers = {
            "AUTHORIZATION": process.env.REACT_APP_LAMBDA_AUTHORIZATION
        }

        if (accno && !partTabChange && current_parts_invoice) {
            setPartList([]);
            setValue(invoice_part_list, []);
            setValue(parts_qty_list, '')
            setValue(parts_num_list, '')
            setValue(parts_descr, '')
            setselectpart('')
            setbtnerr(true)
            const delayApiCall = setTimeout(() => {

                axios.post(process.env.REACT_APP_RMA_INVOICE_VALIDATE_API, request_data, { headers }).then(response => {
                    console.log(response.data)
                    setPartInput(true);
                    if (response) {
                        const past = moment().subtract(1, 'years');
                        const today = moment();
                        const invodate = moment(today).isAfter(response.data.invoice_date, 'year');
                        if (editapi) {
                            setselectpart('')
                        } else {
                            setEditapi(true)
                        }
                        if (invodate == true) {
                            setInvoicedate(true)
                        } else {
                            setInvoicedate(false)
                        }
                        console.log(response.data.part_list)
                        setValue(invoice_part_list, response.data.part_list);
                        setpartdrop(false)
                        ShowSuc(suchidden = true);
                        SetSuMsg(response.data.msg);
                        setPartList(response.data.part_list)
                        setbtnerr(false)
                        // setselectpart('')
                        SetErMsg('');
                        // setValue(parts_num_list, '')
                        ShowErr(errhidden = false);
                        setTimeout(() => {
                            ShowSuc(suchidden = false);
                        }, 2000);
                    }
                }).catch(err => {
                    // setPartInput(true);
                    setbtnerr(true)
                    setpartdrop(false)
                    setselectpart('')
                    setValue(parts_invo_line, '')
                    console.log(err);
                    setPartList([])
                    ShowSuc(suchidden = false);
                    setValue(parts_num_list, '')
                    setPart_description('')
                    setValue(parts_descr, '')
                    setQuantity('')
                    setValue(parts_qty_list, '')
                    SetErMsg(suMSG = err.response.data.msg);
                    ShowErr(errhidden = true);
                    setTimeout(() => {
                        ShowErr(errhidden = false);
                    }, 6000);
                });
            }, 1000);
            return () => clearTimeout(delayApiCall);
        }
        else {
            setEditapi(false)
            if (current_parts_invoice == '') {
                console.log(current_parts_num)
                setselectpart('')
                setValue(parts_num_list, '')
                setPartList([])
            } else {
                if (current_invoice_part_list == '' || current_invoice_part_list == undefined || current_invoice_part_list == null) {
                    setPartList([])
                } else {
                    setPartList(current_invoice_part_list)
                }
                SetpartTabChange(false)
                setselectpart(current_parts_num)
                setValue(parts_num_list, current_parts_num)
            }
        }

    }, [current_parts_invoice])

    useEffect(() => {
        if (partTabChange) {
            setselectpart(current_parts_num);
            setValue(parts_num_list, current_parts_num)
            setPart_description(current_parts_descr)
            setValue(parts_descr, current_parts_descr)
            setValue(parts_qty_list, current_parts_qty)
            setQuantity(current_parts_qty)
            if (current_invoice_part_list == '' || current_invoice_part_list == undefined || current_invoice_part_list == null) {
                setPartList([])
            } else {
                setPartList(current_invoice_part_list)
            }
            SetpartTabChange(false)
            if (current_parts_descr != undefined && current_parts_descr != '') {
                setPartvalid(false)
            }
            else {
                setPartvalid(true)
            }

            var part_desc = part_list.filter(part => part.InvcDtl_PartNum == current_parts_num)
            if ((current_parts_num == undefined || current_parts_num == '') || part_desc != undefined || part_desc.length > 0) {
                setPartvalid(false)
            } else {
                setPartvalid(true)
            }
        } else {
            if (current_parts_num != undefined && current_parts_num != '' && current_parts_invoice != undefined && current_parts_invoice != '') {
                setPartvalid(true)
                var splitpart = current_parts_num.split(' / ');
                console.log(part_list)
                setselectpart(splitpart[0]);
                setValue(parts_num_list, splitpart[0]);
                var part_desc = part_list.filter(part => part.InvcDtl_PartNum == splitpart[0]);

                if (part_desc != undefined && part_desc.length > 0) {
                    setPartvalid(false)
                    setshowpartInvalid(false)
                    setselectpart(current_parts_num)
                    // setValue(parts_num_list, current_parts_num)
                    setPart_description(part_desc[0].InvcDtl_LineDesc)
                    setValue(parts_descr, part_desc[0].InvcDtl_LineDesc)
                    // var qtyflot =
                    setQuantity(parseFloat(part_desc[0].InvcDtl_SellingShipQty).toFixed(2))
                    setValue(parts_invo_line, part_desc[0].InvcDtl_InvoiceLine)
                    setValue(parts_qty_list, parseFloat(part_desc[0].InvcDtl_SellingShipQty).toFixed(2))

                    // if (part_desc[0].InvcDtl_SellingShipQty != '') {
                    //     if (control._formState.errors.parts) {
                    //         if (control._formState.errors.parts[activeRepairIndex].return_qty && control._formState.errors.parts[activeRepairIndex].return_qty.message) {
                    //             control._formState.errors.parts[activeRepairIndex].return_qty.message = '';
                    //         }
                    //     }
                    // }
                    var fvalue = control._formValues.parts;
                    fvalue.forEach((partval, index) => {
                        // console.log(partval)
                        if (partval.part_no != "" && partval.part_no != null && partval.part_no != undefined && partval.part_no != 'undefined') {
                            if (control._formState.errors.parts && control._formState.errors.parts[index] && control._formState.errors.parts[index].part_no && control._formState.errors.parts[index].part_no.message) {
                                control._formState.errors.parts[index].part_no.message = '';
                            }
                        }
                        if (partval.return_qty != '' && partval.return_qty != null && partval.return_qty != undefined && partval.return_qty != 'undefined') {
                            // console.log('qty not empty')
                            // console.log(control._formState)
                            if (control._formState.errors.parts) {
                                if (control._formState.errors.parts[index]?.return_qty && control._formState.errors.parts[index]?.return_qty.message) {
                                    control._formState.errors.parts[index].return_qty.message = '';
                                }
                            }
                        }
                    })
                } else {
                    // setPartList([])
                    setPart_description('')
                    setValue(parts_descr, '')
                    setQuantity('')
                    setValue(parts_qty_list, '')
                }
            } else {
                setselectpart('')
                // setPartList([])
                setPart_description('')
                setValue(parts_descr, '')
                setQuantity('')
                setValue(parts_qty_list, '')
            }
        }
    }, [current_parts_num]);


    const ondraft = async (event) => {
        // event.preventDefault();
        SetIsAllFieldValid(true);
        const curDDate = moment(new Date()).format("MM-DD-YYYY");
        return_Req_dataDraft = getValues();
        return_Req_dataDraft.rma_id = id
        return_Req_dataDraft.rma_file = file_invoice;
        return_Req_dataDraft.is_file_removed = ISfile_removed;

        return_Req_dataDraft.returnsdate = curDDate;
        if (return_Req_dataDraft.parts[0].part_no === '') {
            setisSeiralValidMsg(true)
            setTimeout(() => {
                setisSeiralValidMsg(false)
            }, 4000);
            setFocus('part_no');
        } else {
            SetBtnDisable(enableloader = false);
            return_Req_dataDraft.status = 0;
            var response_data = await edit_return_claim_api(return_Req_dataDraft);
            console.log(response_data)
            if (response_data.success) {
                ShowSuc(!suchidden);
                SetSuMsg(response_data.msg);
                setTimeout(() => {
                    ShowSuc(suchidden = true);
                    SetBtnDisable(enableloader = true);
                    return navigate("/returns");
                }, 3000);
            }
        }
    }
    const onSubmit = async (req_data, e) => {
        e.preventDefault();
        const curDate = moment(new Date()).format("MM-DD-YYYY");
        SetIsAllFieldValid(true);
        SetBtnDisable(enableloader = false);
        console.log(req_data);
        req_data.returnsdate = curDate;
        req_data.rma_id = id
        req_data.rma_file = file_invoice;
        req_data.is_file_removed = ISfile_removed;

        req_data.status = 1;
        var response_data = await edit_return_claim_api(req_data);

        if (response_data.success) {
            console.log(response_data);
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                ShowSuc(suchidden = true);
                SetBtnDisable(enableloader = true);
                return navigate("/returns");
            }, 1000);

        } else {
            SetErMsg(suMSG = response_data.msg);
            ShowErr(errhidden = true);
            setTimeout(() => {
                ShowErr(errhidden = false);
            }, 15000);
            SetBtnDisable(enableloader = true);
        }

    }
    const OnError = (error) => {
        console.log(error)
        var petnum = '';
        var fvalue = control._formValues.parts;
        fvalue.forEach((partval, index) => {
            // console.log(partval)
            if (partval.part_no != "" && partval.part_no != null && partval.part_no != undefined && partval.part_no != 'undefined') {
                if (control._formState.errors.parts && control._formState.errors.parts[index] && control._formState.errors.parts[index].part_no && control._formState.errors.parts[index].part_no.message) {
                    control._formState.errors.parts[index].part_no.message = '';
                }
            }
            if (partval.part_description == null || partval.part_description == '') {
                if (petnum != '') {
                    petnum += ','
                }
                petnum += ' ' + (index + 1)
                setshowpartInvalid(true)
                SetshowERRfields(false);
                setTimeout(() => {
                    setshowpartInvalid(false)

                }, 5000);
            } else {
                SetshowERRfields(true);
            }
        })
        setpartnumber(petnum)
        // console.log(partValid)
        // if (partValid == true) {
        //     setshowpartvalid(true)
        //     SetshowERRfields(false);
        // }
        // SetshowERRfields(true);
        setTimeout(() => {
            SetshowERRfields(false);
        }, 3000);
    }

    const repairRemove = (index, event) => {
        event.preventDefault();
        parts.splice(index, 1);
        setbtnerr(false)
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        SetpartTabChange(true)
        setActiveRepairIndex(repairIdx);
    }
    const addRepair = () => {
        console.log(parts)
        setbtnerr(true)
        setPartList([])
        setQuantity('')
        setPart_description('')
        setPartvalid(false)
        var partId = parts.length + '-0'
        parts.push({ invoice_no: '', invoice_no_line: '', part_no: '', part_description: '', return_qty: '', reason_code: '', replace_required: '', pickup_arrangements: '', comments: '', invoice_part_list: [] })
        var len = parts.length - 1;
        setActiveRepairIndex(len);
        var invo_part_list = "parts." + len + ".invoice_part_list"
        setValue(invo_part_list, [])

    }

    const activeRIndex = (index, event) => {
        // var adasd = getValues();
        // console.log(adasd)
        event.preventDefault();
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        setActiveRepairIndex(index);
        SetpartTabChange(true)
        setEditapi(false)
        var getVal = getValues();
        // console.log(getVal);

        if (getVal.parts[index].part_no == '') {
            var rep_comp_val = "parts." + index + ".part_description";
            setPart_description('')
            setQuantity('')
            setValue(rep_comp_val, '');
            var part_no_val = "parts." + index + ".part_no";
            setValue(part_no_val, '');
            setPartList([])
        }
    }
    const numValidate = (e) => {
        e.target.value = e.target.value.replace(/^0+/, '')
        if (parseInt(e.target.value) > parseInt(partquantity)) {
            setQtyerror(true)
        } else {
            setQtyerror(false)
        }
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    }
    // useEffect(() => {
    //     // console.log(current_parts_qty)
    //     // console.log(partquantity)
    //     if (parseInt(current_parts_qty) > parseInt(partquantity)) {
    //         setQtyerror(true)
    //     } else {
    //         setQtyerror(false)
    //     }
    // }, [current_parts_qty])

    // const handleInserts = (payload) => {
    //     // console.log('Change received:', payload);
    //     if (localStorage.getItem('user_id') == payload.new.user_id) {
    //         if (localStorage.getItem('role') != payload.new.role) {
    //             localStorage.setItem('role', payload.new.role);
    //             setUserRole(payload.new.role);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (localStorage.getItem('role') != undefined) {
    //         setUserRole(localStorage.getItem('role'))
    //     }
    //     const channel = supabase
    //         .channel('user')
    //         .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'user' }, handleInserts)
    //         .subscribe();
    //     return () => {
    //         channel.unsubscribe();
    //     };
    // }, []);

    return (

        <div className="addclaim-container">
            <ReturnMenu />
            <div className="container details_All">
                <div hidden={enableloader} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                {errhidden && <div className='toaster fail mdesc rtnerr'>
                    <h2>{erMSG}</h2>
                </div>}
                {suchidden && < div className='toaster suc returns_suc'>
                    <h2>{suMSG}</h2>
                </div>}
                {/* {
                    isSeValidMsg && <small className='Errmsg Warrenty Popup'>Please Enter Invoice Number</small>
                } */}
                {
                    showpartvalid && <small className='Errmsg Warrenty Popup prtno invopaart'>Enter Valid Invoice Number/Part Number for Part {partnumber}</small>
                }
                {showERRfields && <small className='Errmsg Warrenty Popup'>** Please fill the all parts tab fields</small>
                }
                {
                    show && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    By cancelling, any data you have provided will not be saved.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_no' onClick={staysamepage}>Cancel</button>
                                <button className='can_yes' onClick={backtoclaims}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    reason && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    Restocking fees may apply.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={cancelreson}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    qtyerror && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    Quantity is greater than invoiced quantity. Restocking fees may apply.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={cancelqty}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    invoicedate && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    Invoice is over one year old restocking fees may apply.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={cancelinvodate}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    redirectpop && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Notice
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    RMA Already Placed.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={gotoview}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {(() => {
                    if (userRole === 2) {
                        return <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Confirmation
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        Admin has changed your status.Please contact Admin.
                                    </p>
                                </div>
                                <hr />
                                <div className='cancel_btns'>
                                    <button className='can_yes' onClick={goToList}>Ok</button>
                                </div>
                            </div>
                        </div>
                    }
                })()}
                <div className="claim_top_bar">
                    <div className=" user_topbar">
                        <h3>Edit RMA</h3>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                                <p>LOG OUT</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="previous_icon row">
                    <div className="col-md-2" onClick={cancelpop}>
                        <img src={back_icon} />
                        <span> Back to RMA</span>
                    </div>
                </div>
                <div className="card container edit_card rtneditcard" >
                    <div className="card-body">
                        <form className="">
                            <div className="basic_return rtncard">
                                <div className="return_basic">
                                    <p>Product Information</p>
                                </div>
                                <div className="parts_section">
                                    <div className="repair-tabs rma_parts">
                                        {parts.map((repair, index) => (
                                            <React.Fragment key={index + 'sS'}>
                                                <div className="repair-intabs tabsspace">
                                                    <button
                                                        className={index === activeRepairIndex ? 'active' : ''}
                                                        onClick={(event) => activeRIndex(index, event)}
                                                    >
                                                        Part {index + 1}
                                                    </button>
                                                    {parts.length > 1 && (
                                                        <button key={index + 'a'}
                                                            className='repair-tabss close active' onClick={(event) => { repairRemove(index, event); removeRepair(index) }}>
                                                            X
                                                        </button>)}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                        <button type="button" className='addrepairbtn' onClick={addRepair}>
                                            +
                                        </button>
                                    </div>
                                    <div className="field_section">
                                        {parts.map((repair, index) => (
                                            <div key={index + 'g'} className={index === activeRepairIndex ? 'active' : 'inactive'}>
                                                {(
                                                    <>
                                                        <div className="row section_gap">
                                                            <div className="col-md-6">
                                                                <label>Maxon Invoice #*</label><br />
                                                                <input type="text" className="form-control" placeholder="Enter the Invoice Number"  {...register(`parts.${index}.invoice_no`, {
                                                                    required:
                                                                    {
                                                                        value: IsAllFieldValid, message: 'Maxon Invoice is required'
                                                                    }
                                                                }
                                                                )} />
                                                                {IsAllFieldValid && errors?.parts?.[index]?.invoice_no && <small className='errMsg'>Invoice is required</small>}
                                                                {
                                                                    isSeValidMsg && <small className='invo_class'>Please Enter Invoice #</small>
                                                                }
                                                            </div>
                                                            <div className="col-md-6 involine">
                                                                <input type="text" className="form-control" placeholder="Enter the Invoice Number"  {...register(`parts.${index}.invoice_no_line`,
                                                                )} />
                                                            </div>
                                                        </div>
                                                        <div className="row section_gap">
                                                            <div className="col-md-6">

                                                                {/* <label>Part Number*</label><br />
                                                                <select value={selectpart} className="form-select" aria-label="Default select example" {...register(`parts.${index}.part_no`, {
                                                                    required:
                                                                    {
                                                                        value: IsAllFieldValid, message: 'Part Number is required'
                                                                    }
                                                                })} >
                                                                    <option value=''>Select</option>
                                                                    {part_list && part_list.map((pnList) => {
                                                                        return (
                                                                            <option key={pnList.InvcDtl_PartNum} value={pnList.InvcDtl_PartNum}>{pnList.InvcDtl_PartNum}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {IsAllFieldValid && errors?.parts?.[index]?.part_no?.type === "required" &&
                                                                    <small className='errMsg'>{errors?.parts?.[index]?.part_no.message}</small>} */}


                                                                <label>Part Number*</label><br />
                                                                <input type="text" list="partlists" autoComplete="off" className="form-control" placeholder="Enter the Part Number" {...register(`parts.${index}.part_no`, {
                                                                    required: {
                                                                        value: IsAllFieldValid, message: 'Part Number is required'
                                                                    }
                                                                })} />
                                                                <datalist id="partlists">
                                                                    {part_list && part_list.length > 0 && part_list.map((pnList) => (
                                                                        <option key={pnList.InvcDtl_PartNum} value={pnList.InvcDtl_PartNum + ' / ' + pnList.InvcDtl_LineDesc} />
                                                                    ))}
                                                                </datalist>
                                                                {partValid ? <small className='errMsg'>Enter valid Part Number <br /></small> : ''}
                                                                {IsAllFieldValid && errors?.parts?.[index]?.part_no?.type === "required" &&
                                                                    <small className='errMsg'>{errors?.parts?.[index]?.part_no.message}</small>}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Part Description</label><br />
                                                                <textarea rows="2" disabled type="text" readOnly className="form-control" placeholder="Enter the Part Description"  {...register(`parts.${index}.part_description`, {
                                                                    required: {
                                                                        value: IsAllFieldValid
                                                                    }
                                                                })} />
                                                            </div>
                                                        </div>
                                                        <div className="row section_gap">
                                                            <div className="col-md-6">
                                                                <label>Quantity*</label><br />
                                                                <input type="number" className="form-control" placeholder="Enter the Quantity" maxLength={10} onInput={numValidate}
                                                                    onWheel={(e) => e.target.blur()} defaultValue={partquantity}
                                                                    {...register(`parts.${index}.return_qty`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Quantity is required' }
                                                                    })} />
                                                                {IsAllFieldValid && errors?.parts?.[index]?.return_qty?.type === "required" && (
                                                                    <small className='errMsg'>{errors?.parts?.[index]?.return_qty.message}</small>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Reason Code*</label><br />
                                                                <select className="form-select" aria-label="Default select example"{...register(`parts.${index}.reason_code`, {
                                                                    required:
                                                                        { value: IsAllFieldValid, message: 'Reason Code is required' }
                                                                })}>
                                                                    <option value=''>Select</option>
                                                                    <option value="1A">Customer Error</option>
                                                                    <option value="5A">Maxon Error</option>
                                                                    <option value="1D">Overstock</option>
                                                                </select>
                                                                {IsAllFieldValid && errors?.parts?.[index]?.reason_code?.type === "required" && (
                                                                    <small className='errMsg'>{errors?.parts?.[index]?.reason_code.message}</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row section_gap">
                                                            <div className="col-md-6">
                                                                <label>Are replacements required?*</label><br />
                                                                <select className="form-select" aria-label="Default select example"{...register(`parts.${index}.replace_required`, {
                                                                    required: IsAllFieldValid
                                                                })}>
                                                                    <option value=''>Select</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                                {IsAllFieldValid && errors?.parts?.[index]?.replace_required?.type === "required" && (
                                                                    <small className='errMsg'>Replacements is required </small>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Are pick up arrangements needed?*</label><br />
                                                                <select className="form-select" aria-label="Default select example"{...register(`parts.${index}.pickup_arrangements`, {
                                                                    required:
                                                                        { value: IsAllFieldValid, message: 'Arrangements needed is required' }
                                                                })}>
                                                                    <option value=''>Select</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                                {IsAllFieldValid && errors?.parts?.[index]?.pickup_arrangements?.type === "required" && (
                                                                    <small className='errMsg'>Arrangements is required</small>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>Comments*</label><br />
                                                                <textarea type="text" className="form-control" placeholder="Type here" {...register(`parts.${index}.comments`, {
                                                                    required: IsAllFieldValid,
                                                                })} ></textarea>
                                                                {IsAllFieldValid && errors?.parts?.[index]?.comments?.type === "required" && (
                                                                    <small className='errMsg'>Comments is required</small>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6 involine">
                                                                <input type="text" className="form-control" placeholder="Enter the Invoice Number"  {...register(`parts.${index}.invoice_part_list`,
                                                                )} />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="return_basic rma_file">
                                <p>File Upload</p>
                            </div>
                            <div className="file-class">
                                <div className="row asddsd">
                                    <div className="col-md-6">
                                        <div className='invo_file_rma'>
                                            <div className='file_input'>
                                                <label>Upload File</label>
                                                <input type="file" className="form-control"
                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                    {...register("rma_file",
                                                    )} />
                                                <p className='file_format'>*Formats Accepted jpg,jpeg,png,pdf. Max file size 15MB</p>
                                            </div>
                                            {(!ISfile_removed && file_invoiceremove && invoice_file_edit !== null &&
                                                <div className="remove_file">
                                                    <a href={invoice_file_edit} target='_blank' className="LinkInvoice">Invoice </a>
                                                    <span className="removeInvoice" onClick={InvoiceFileRemove}>x</span>
                                                </div>)}
                                            {fileerr && <small className='errMsg'>*Invalid file format</small>}
                                            {fileerrsize && <small className='errMsg'>*File size exceeds the allowed limit of 15MB</small>}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </form>
                        {btnerr && <p className="hidetext"> Access to draft and submit requires a valid invoice#,part number and other informations.</p>
                        }
                        <div className="return_btns">
                            <button className="rtn_can" onClick={cancelpop}><img src={CanCelBtn} alt='cancel-icon' /> Cancel</button>
                            <button className="rtn_draft" onClick={handleSubmit(ondraft, OnError)} disabled={btnerr}>
                                <img src={DraftBTNIcon} alt='draft-icon' /> Save Draft </button>
                            <button className="rtn_submit" onClick={handleSubmit(onSubmit, OnError)} disabled={btnerr}>
                                <img src={SubmitBTNIcon} alt='submit-icon' /> Submit</button>
                        </div>
                    </div>
                </div>
                <div className=" claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser" >
                            <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Editreturns;