import supabase from './supabaseconnect';

export async function category_list_api() {

    let { data, error } = await supabase.from('category').select('*').eq('status', '1');
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "category_list", "data": data }
        return (response_data);
    }
}

export async function gate_type_list_api(product_family,category_id,is_edit) {
    if((category_id == undefined || category_id == '')){
        category_id = 0;
    }

    if (is_edit) {
        if((category_id != undefined && category_id != '')){
            var { data, error } = await supabase.from('gate_type').select('*').eq('status', '1').eq('category_id', category_id).eq('product_family', product_family);
        }else{
            var { data, error } = await supabase.from('gate_type').select('*').eq('status', '1').eq('product_family', product_family);
        }
    }else{
        if(product_family != undefined && product_family != '' && product_family != null){
            var { data, error } = await supabase.from('gate_type').select('*').eq('status', '1').eq('category_id', category_id).eq('product_family', product_family);
        }else{
            var { data, error } = await supabase.from('gate_type').select('*').eq('status', '1').eq('category_id', category_id);
        }
    }

    // let { data, error } = await supabase.from('gate_type').select('*').eq('status', '1').eq('category_id', category_id);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "gate_type_list", "data": data }
        return (response_data);
    }
}

export async function component_list_api(gate_type_id, is_edit) {
    if((gate_type_id == undefined || gate_type_id == '')){
        gate_type_id = 0;
    }
    if (is_edit) {
        if(gate_type_id != undefined && gate_type_id != ''){
            var { data, error } = await supabase.from('component').select('*').eq('gate_type_id', gate_type_id).eq('status', '1');
        }else{
            var { data, error } = await supabase.from('component').select('*').eq('status', '1');
        }
    }else{
        var { data, error } = await supabase.from('component').select('*').eq('status', '1').eq('gate_type_id', gate_type_id);
    }

    // let { data, error } = await supabase.from('component').select('*').eq('status', '1').eq('gate_type_id', gate_type_id);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "component_list", "data": data }
        return (response_data);
    }
}


export async function validate_serial_api(serial_no) {

    let { data, error } = await supabase.from('serial_no').select('*').eq('SerialNumber', serial_no);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        if (data.length == 0) {
            var response_data = { "success": false, "msg": "Invalid Serial Number" }
            return (response_data);
        } else {
            var response_data = { "success": true, "msg": "Valid Serial Number", "data": data }
            return (response_data);
        }
    }
}

export async function hours_list_api(component_id) {

    let { data, error } = await supabase.from('component').select('*').eq('id', component_id);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "labour hours", "data": data }
        return (response_data);
    }
}

export async function parts_listing_api() {

    let { data, error } = await supabase.from('parts_listing').select('*');
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "Parts Listing", "data": data }
        return (response_data);
    }
}

export async function parts_list_search_api(partno) {

    var pattern = "PartNum.ilike."+partno+"%";

    let { data, error } = await supabase.from('parts_listing').select('*').or(pattern);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "Parts Listing", "data": data }
        return (response_data);
    }
}

export async function get_part_description_api(part_no) {

    let { data, error } = await supabase.from('parts_listing').select('PartDescription').eq('PartNum', part_no);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        if (data.length == 0) {
            data.push({ "PartDescription": '' });
        }
        var response_data = { "success": true, "msg": "Parts Listing", "data": data }
        return (response_data);
    }
}

export async function get_part_number_api(part_description) {

    let { data, error } = await supabase.from('parts_listing').select('PartNum').eq('PartDescription', part_description);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        if (data.length == 0) {
            data.push({ "PartNum": '' });
        }
        var response_data = { "success": true, "msg": "Parts Listing", "data": data }
        return (response_data);
    }
}

export async function get_web_reference_no_api() {

    let { data, error } = await supabase.from('claim_ref_seq').select('seq_no').order('seq_no', { ascending: false });
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        if (data.length > 0) {
            var seq_no = parseInt(data[0].seq_no) + 5001
            var web_reference_no = "W" + seq_no;
        } else {
            var web_reference_no = "W" + 5001
        }
        var response_data = { "success": true, "msg": "Parts Listing", "web_reference_no": web_reference_no }
        return (response_data);
    }
}