import supabase from './supabaseconnect';

export async function add_return_claim_api(req_data) {
    console.log(req_data);

    // const rma_no = Math.floor(10000 + Math.random() * 90000);

    var { error } = await supabase.auth.refreshSession();

    var invoice_no = '';

    req_data.parts.forEach((val,index) => {
        if(index != 0){
            invoice_no += ",";
        }
        invoice_no += val.invoice_no;
    });

    if(req_data.status == 1){
        req_data.status_value = 'Submitted';
    }else{
        req_data.status_value = 'Draft';
    }

    const { data: { user } } = await supabase.auth.getUser()

    var user_id = user.id;

    var { data, error } = await supabase.from('user').select('*').eq('user_id', user_id);

    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {

        if(data.length > 0){
            var maxon_account_no = data[0].account_no;
        }else{
            var maxon_account_no = '';
        }


        var { data, error } = await supabase.from('rma_base_table').insert({"invoice_no": invoice_no, "status": req_data.status, "return_date": req_data.returnsdate,"status_value":req_data.status_value,"maxon_account_no":maxon_account_no }).select('id');


        if (error) {
            if(error.message == 'new row violates row-level security policy for table "claim"'){
                error.message = 'Admin changed your access.Please Contact Admin.'
            }
            var response_data = { "success": false, "msg": error.message }
            return (response_data);
        } else {
            var rma_id = data[0].id;

            var return_details = []
            req_data.parts.forEach((val,index) => {
                var part_line_no = index + 1;

                if(val.invoice_part_list != undefined && val.invoice_part_list != null && val.invoice_part_list != ''){
                    val.invoice_part_list = val.invoice_part_list;
                }else{
                    val.invoice_part_list = [];
                }

                return_details.push({ "rma_id": rma_id, "invoice_no": val.invoice_no, "invoice_line": val.invoice_no_line, "part_no": val.part_no, "part_description": val.part_description, "return_qty": val.return_qty, "reason_code": val.reason_code, "replace_required": val.replace_required, "pickup_arrangements": val.pickup_arrangements, "comments": val.comments,"part_line_no":part_line_no, "status": req_data.status,"invoice_part_list":val.invoice_part_list
                })
            });

            var { data1, error } = await supabase.from('rma_return_part_list').insert(return_details);

            if (error) {
                var response_data = { "success": false, "msg": error.message }
                return (response_data);
            } else {

                if(req_data.status == 1){
                    var { data1, error } = await supabase.from('rma_epicor_sync_log').insert({"rma_id":rma_id});;

                    if (error) {
                        var response_data = { "success": false, "msg": error.message }
                        return (response_data);
                    } else {

                        // if(req_data.is_upload){
                            uploadFile(rma_id,req_data);
                        // }

                        var response_data = { "success": true, "msg": "RMA Submitted Successfully" }
                        return (response_data);
                    }
                }else{

                    // if(req_data.is_upload){
                        uploadFile(rma_id,req_data);
                    // }

                    var response_data = { "success": true, "msg": "Draft Added Successfully" }
                    return (response_data);
                }
            }
        }
    }
}

async function uploadFile(rma_id,req_data){
    console.log(req_data.rma_file)

    if(req_data.rma_file != '' && req_data.rma_file != undefined && req_data.rma_file != null && req_data.rma_file != 'undefined'){
        const { data, error } = await supabase.storage.from('RMAFiles').upload(rma_id, req_data.rma_file)
        if (error) {
            console.log(error);
        } else {
            console.log("Uploaded Successfully");
        }
    }
}
