import supabase from './supabaseconnect';
import moment from 'moment';

async function admin_claim_list_api(filter_status , filter_date , col,order,claimSearch,start,limit) {
    var claim_status = [];
    // console.log(filter_status)

    if(filter_status.draft){
        claim_status.push('Draft');
    }
    if(filter_status.submitted){
        claim_status.push('Submitted');
    }
    if(filter_status.entryhold){
        claim_status.push('Entry Hold');
    }
    if(filter_status.approved){
        claim_status.push('Approved');
    }
    if(filter_status.rejected){
        claim_status.push('Rejected');
    }
    if(filter_status.closed){
        claim_status.push('Closed');
        claim_status.push('Payment Processed');
        claim_status.push('Credit Memo Processed');
    }
    if(filter_status.error){
        claim_status.push('Error');
    }
    if(filter_status.failed){
        claim_status.push('Failed');
    }
    if(filter_status.processingcheck){
        claim_status.push('Processing Check');
    }
    if(filter_status.processingcreditmemo){
        claim_status.push('Processing Credit Memo');
    }

    if(filter_date.length > 0){
        if(filter_date[0] == undefined && filter_date[1] == undefined){
            filter_date = [];
        }else if(filter_date[1] == null){
            filter_date[1] = undefined;
        }
    }

    if(claimSearch != undefined && claimSearch != ''){
        claimSearch = claimSearch.replace(/,/g, " ");
    }

    // console.log(claim_status)
    // console.log(filter_date)

    var { error } = await supabase.auth.refreshSession();

    if((col != undefined && order != undefined && col != '' && order != '') || (claimSearch != undefined && claimSearch != '') || claim_status.length > 0 || filter_date.length > 0)
    {
        if(col != undefined && order != undefined && col != '' && order != '' && claimSearch != undefined && claimSearch != '' && claim_status.length > 0 && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).gte('web_entry_date',from_date).lte('web_entry_date',to_date).or(filter_values).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).gte('web_entry_date',from_date).lte('web_entry_date',to_date).or(filter_values).order(col);
            }
        }else if(col != undefined && order != undefined && col != '' && order != '' && claim_status.length > 0 && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).gte('web_entry_date',from_date).lte('web_entry_date',to_date).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).gte('web_entry_date',from_date).lte('web_entry_date',to_date).order(col);
            }
        }else if(claimSearch != undefined && claimSearch != '' && claim_status.length > 0 && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";

            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).gte('web_entry_date',from_date).lte('web_entry_date',to_date).or(filter_values);

        }else if(col != undefined && order != undefined && col != '' && order != '' && claimSearch != undefined && claimSearch != '' && claim_status.length > 0)
        {
            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).or(filter_values).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).or(filter_values).order(col);
            }
        }else if(col != undefined && order != undefined && col != '' && order != '' && claimSearch != undefined && claimSearch != '' && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').gte('web_entry_date',from_date).lte('web_entry_date',to_date).or(filter_values).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').gte('web_entry_date',from_date).lte('web_entry_date',to_date).or(filter_values).order(col);
            }
        }else if(claim_status.length > 0 && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).gte('web_entry_date',from_date).lte('web_entry_date',to_date);

        }else if(col != undefined && order != undefined && col != '' && order != '' && claim_status.length > 0)
        {
            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).order(col);
            }
        }else if(claimSearch != undefined && claimSearch != '' && claim_status.length > 0)
        {
            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status).or(filter_values);
        }else if(col != undefined && order != undefined && col != '' && order != '' && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').gte('web_entry_date',from_date).lte('web_entry_date',to_date).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').gte('web_entry_date',from_date).lte('web_entry_date',to_date).order(col);
            }
        }else if(claimSearch != undefined && claimSearch != '' && filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');

            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').gte('web_entry_date',from_date).lte('web_entry_date',to_date).or(filter_values);

        }else if(col != undefined && order != undefined && col != '' && order != '' && claimSearch != undefined && claimSearch != '')
        {
            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').or(filter_values).order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').or(filter_values).order(col);
            }
        }else if(claim_status.length > 0)
        {
            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').in('status_value',claim_status);

        }else if(filter_date.length > 0)
        {
            var from_date= moment(filter_date[0]).format('YYYY-MM-DD');
            var to_date= moment(filter_date[1]).format('YYYY-MM-DD');
            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').gte('web_entry_date',from_date).lte('web_entry_date',to_date);

        }else if(col != undefined && order != undefined && col != '' && order != '')
        {
            if(order == 'DSC'){
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').order(col,{ascending:false});
            }else{
                var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').order(col);
            }
        }else if(claimSearch != undefined && claimSearch != '')
        {
            var filter_values = "web_reference_no.ilike.%"+claimSearch +"%,model_no.ilike.%"+claimSearch +"%,serial_no.ilike.%"+claimSearch+"%,invoice_no.ilike.%"+claimSearch+"%";
            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').or(filter_values);
        }else{
            var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').order('created_at',{ascending:false});
        }
    }else{
        var Query = supabase.from('claim').select('*',  { count: 'exact' }).neq('status','2').order('created_at',{ascending:false});
    }

    if(start == undefined || start == null || start == '' || isNaN(start)){
        start = 0
    }

    if(limit == undefined || limit == null || limit == '' || isNaN(limit)){
        limit = 5
    }

    limit =  parseInt(limit) + parseInt(start) - 1;

    var { data, count, error } = await Query.range(start,limit);

    if(error){
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else{
        var resepnse_data = {"success":true,"msg":"claim_list","data":data,"total_count":count}
        return(resepnse_data);
    }
}

export default admin_claim_list_api;