import supabase from './supabaseconnect';
import axios from 'axios';
import * as XLSX from 'xlsx';
import moment from 'moment';

export async function bulk_claim_upload_api(req_data){
    // await supabase.storage.from('bucket').remove(['object-key-1', 'object-key-2'])
    var json_data = {"claim":req_data.record}
    var file_name = moment().format('YYYY-MM-DD HH:mm:ss:SSS') +" - "+ req_data.file_name;
    console.log(file_name);

    var { error } = await supabase.auth.refreshSession();

    const { data: { user } } = await supabase.auth.getUser()

    var user_id = user.id;

    var { data, error } =await supabase.from('user').select('*').eq('user_id',user_id).eq('role','0');

    if(error){
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else
    {
        if(data.length > 0){
            var { data, error } =await supabase.from('temp_bulk_upload').insert({req_data: json_data,file_name:file_name});

            if(error){
                var resepnse_data = {"success":false,"msg":error.message}
                return(resepnse_data);
            }else
            {
                if(req_data.upload_file != '' && req_data.upload_file != undefined && req_data.upload_file != null && req_data.upload_file != 'undefined'){
                    const file_path = "upload/"+file_name;
                    var { data, error } = await supabase.storage.from('BulkClaimUpload').upload(file_path, req_data.upload_file)
                    if (error) {
                        var resepnse_data = {"success":false,"msg":error.message}
                        return(resepnse_data);
                    } else {
                        var resepnse_data = {"success":true,"msg":'Claim Upload Started Successfully'}
                        return(resepnse_data);
                    }
                }
            }
        }else{
            var resepnse_data = {"success":false,"msg":'Admin changed your access.Please Contact admin.'}
            return(resepnse_data);
        }
    }
}